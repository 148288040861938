import React from "react";

class About extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    /**
     * Your ajax will goes here to get data then call setState
     */
  }

  render() {
    return (
      <React.Fragment>
        <div className="overflow-hidden">
          <section id="about" className="about-us ptb-100 background-shape-img position-relative">
            <div className="animated-shape-wrap">
              <div className="animated-shape-item"></div>
              <div className="animated-shape-item"></div>
              <div className="animated-shape-item"></div>
              <div className="animated-shape-item"></div>
              <div className="animated-shape-item"></div>
            </div>
            <div className="container">
              <div className="row align-items-center justify-content-lg-between justify-content-md-center justify-content-sm-center">
                <div className="col-md-12 col-lg-6 mb-5 mb-md-5 mb-sm-5 mb-lg-0">
                  <div className="about-content-left">
                    <h2>We are Dedicated to Helping You</h2>
                    <p>
                      We are a US-based software company with 12 years of industry experience, committed to leveraging AI to transform businesses. Our team is passionate about innovation, as evidenced by our pending patent. Customer support is our top priority, and we ensure personal care for every client.
                    </p>
                    <ul className="dot-circle pt-3">
                      <li>12 years of industry experience in software development.</li>
                      <li>Dedicated to leveraging AI for business transformation.</li>
                      <li>One pending patent showcasing our commitment to innovation.</li>
                      <li>Personalized customer support is our top priority.</li>
                      <li>Based in the USA, serving clients worldwide.</li>
                      <li>Experienced team ensuring high-quality solutions.</li>
                    </ul>

                  </div>
                </div>
                <div className="col-sm-5 col-md-5 col-lg-4">
                  <div className="about-content-right">
                    <img src="assets/img/app-mobile-image-2.png" alt="about us" className="img-fluid" />
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </React.Fragment>
    );
  }
}

export default About;
