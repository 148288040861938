import React from "react";
import { connect } from "react-redux";
import { apiCall } from "../../api";
import { withRouter } from "react-router-dom";
import PricingProtected from "../PricingProtected";
import Modal from "react-modal";

Modal.setAppElement("#root");

class ProfilePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      first_name: "",
      last_name: "",
      email: "",
      company: "",
      phone: "",
      address_line1: "",
      address_line2: "",
      billing_same: false,
      city: "",
      state: "",
      country: "",
      zip_code: "",
      billing_address_line1: "",
      billing_address_line2: "",
      billing_city: "",
      billing_state: "",
      billing_zip_code: "",
      billing_country: "",
      disableBtn: false,
      btnText: "Update Profile",
      errorMessage: "",
      successMessage: "",
      subscribedPlan: "", // Add subscribedPlan to state
      modalIsOpen: false,
      modalMessage: "",
      modalType: "success", // 'success' or 'error'
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleFormValueChange = this.handleFormValueChange.bind(this);
    this.message = this.message.bind(this);
    this.loadProfile = this.loadProfile.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.checkPaymentStatus = this.checkPaymentStatus.bind(this);
    this.getQueryParams = this.getQueryParams.bind(this);
    this.removeQueryParams = this.removeQueryParams.bind(this);
  }

  componentDidMount() {
    this.loadProfile();
    this.checkPaymentStatus();
  }

  changeBtnText = (btnText) => {
    this.setState({ btnText });
  };

  handleFormValueChange(inputName, event) {
    let stateValue = {};
    stateValue[inputName] =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    this.setState(stateValue);
  }

  validatePhoneNumber(phone) {
    const phoneRegex = /^\+\d{11,14}$/;
    return phoneRegex.test(phone);
  }

  loadProfile() {
    const idToken = localStorage.getItem("idToken");
    apiCall(
      "/user_details",
      { params: {} },
      {
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
      }
    )
      .then((response) => {
        if (response.data.data) {
          this.setState({
            ...response.data.data,
            subscribedPlan: response.data.data.plan || "",
            successMessage: "Profile loaded successfully!",
            errorMessage: "",
          });
        } else {
          this.setState({
            errorMessage: "Failed to load profile.",
          });
        }
        this.message(false);
      })
      .catch((error) => {
        console.error("Profile load error:", error);
        this.setState({
          errorMessage: "There was an error loading the profile. Please try again.",
        });
        this.message(true);

        // Clear local storage and redirect to main page
        localStorage.clear();
        this.props.history.push('/');
      });
  }

  handleSubmit(event) {
    event.preventDefault();

    const {
      first_name,
      last_name,
      company,
      phone,
      address_line1,
      address_line2,
      billing_same,
      city,
      state,
      country,
      zip_code,
      billing_address_line1,
      billing_address_line2,
      billing_city,
      billing_state,
      billing_zip_code,
      billing_country,
    } = this.state;

    if (!first_name || !last_name || !company) {
      this.setState({ errorMessage: "Please fill in all required fields." });
      this.message(true);
      return;
    }

    if (phone && !this.validatePhoneNumber(phone)) {
      this.setState({ errorMessage: "Please enter a valid phone number in the format +16467892336." });
      this.message(true);
      return;
    }

    this.setState({ disableBtn: true, btnText: "Updating..." });

    const idToken = localStorage.getItem("idToken");

    apiCall(
      "/update_profile",
      {
        params: {
          first_name,
          last_name,
          company,
          phone,
          address_line1,
          address_line2,
          billing_same,
          city,
          state,
          country,
          zip_code,
          billing_address_line1,
          billing_address_line2,
          billing_city,
          billing_state,
          billing_zip_code,
          billing_country,
        },
      },
      {
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
      }
    )
      .then((response) => {
        if (response.data.data && response.data.data.status === "Success") {
          this.setState({
            disableBtn: false,
            btnText: "Update Profile",
            successMessage: "Profile updated successfully!",
            errorMessage: "",
          });
          this.message(false);
        } else {
          this.setState({
            disableBtn: false,
            btnText: "Update Profile",
            errorMessage: "There was an error updating the profile. Please try again.",
          });
          this.message(true);
        }
      })
      .catch((error) => {
        console.error("Profile update error:", error);
        this.setState({
          disableBtn: false,
          btnText: "Update Profile",
          errorMessage: "There was an error updating the profile. Please try again.",
        });
        this.message(true);

        // Clear local storage and redirect to main page
        localStorage.clear();
        this.props.history.push('/');
      });
  }

  message(error) {
    const messageBox = document.querySelector('.profile-page .message-box');
    if (messageBox.classList.contains("d-none")) {
      messageBox.classList.remove("d-none");
    }
    messageBox.classList.add("d-block");
    if (error) {
      if (messageBox.classList.contains("alert-success")) {
        messageBox.classList.remove("alert-success");
      }
      messageBox.classList.add("alert-danger");
      messageBox.innerHTML = this.state.errorMessage;
    } else {
      if (messageBox.classList.contains("alert-danger")) {
        messageBox.classList.remove("alert-danger");
      }
      messageBox.classList.add("alert-success");
      messageBox.innerHTML = this.state.successMessage;
    }
  }

  getQueryParams() {
    const params = new URLSearchParams(this.props.location.search);
    return {
      session_id: params.get("session_id"),
      payment_id: params.get("payment_id"),
      cancelled: params.get("cancelled")
    };
  }

  removeQueryParams() {
    const { location, history } = this.props;
    history.replace(location.pathname);
  }

  checkPaymentStatus() {
    const { session_id, payment_id, cancelled } = this.getQueryParams();

    if (session_id && payment_id) {
      const payload = cancelled ? { data: { payment_id, failed: true } } : { data: { session_id, payment_id } };

      apiCall("/check_payment_status", payload)
        .then((response) => {
          if (response.data.status === "Success") {
            this.setState({ modalIsOpen: true, modalMessage: "Subscription successful!", modalType: "success" });
          } else {
            this.setState({ modalIsOpen: true, modalMessage: "Subscription failed.", modalType: "error" });
          }
          this.loadProfile(); // Reload profile to reflect latest changes
          this.removeQueryParams(); // Remove query params from URL
        })
        .catch((error) => {
          console.error("API call error:", error);
          this.setState({ modalIsOpen: true, modalMessage: "Subscription failed.", modalType: "error" });
          this.removeQueryParams(); // Remove query params from URL
        });
    }
  }

  closeModal() {
    this.setState({ modalIsOpen: false, modalMessage: "", modalType: "success" });
  }

  render() {
    const { modalIsOpen, modalMessage, modalType } = this.state;

    return (
      <React.Fragment>
        <section className="page-header-section ptb-100 bg-image full-height" image-overlay="8">
          <div className="background-image-wraper" style={{ backgroundImage: "url(assets/img/cta-bg.jpg)", opacity: 1 }}></div>
          <div className="container">
            <div className="row align-items-center justify-content-center">
              <div className="col-12 col-md-8 col-lg-6">
                <div className="profile-page p-5 gray-light-bg rounded shadow">
                  <div className="profile-header text-center">
                    <a href="/"><img src="assets/img/logo-color.png" className="img-fluid mb-3" alt="Logo" /></a>
                    <h4 className="mb-5">Your Profile</h4>
                  </div>
                  <div className="message-box d-none alert alert-success"></div>
                  <form className="profile-form" onSubmit={this.handleSubmit}>
                    <div className="form-group">
                      <label className="pb-1">First Name</label>
                      <input value={this.state.first_name} onChange={e => this.handleFormValueChange("first_name", e)} type="text" className="form-control" placeholder="Enter your first name" />
                    </div>
                    <div className="form-group">
                      <label className="pb-1">Last Name</label>
                      <input value={this.state.last_name} onChange={e => this.handleFormValueChange("last_name", e)} type="text" className="form-control" placeholder="Enter your last name" />
                    </div>
                    <div className="form-group">
                      <label className="pb-1">Email Address</label>
                      <input value={this.state.email} onChange={e => this.handleFormValueChange("email", e)} type="email" className="form-control" placeholder="name@address.com" disabled />
                    </div>
                    <div className="form-group">
                      <label className="pb-1">Company</label>
                      <input value={this.state.company} onChange={e => this.handleFormValueChange("company", e)} type="text" className="form-control" placeholder="Enter your company" />
                    </div>
                    <div className="form-group">
                      <label className="pb-1">Phone</label>
                      <input value={this.state.phone} onChange={e => this.handleFormValueChange("phone", e)} type="text" className="form-control" placeholder="Enter your phone number" />
                    </div>
                    <div className="form-group">
                      <label className="pb-1">Address Line 1</label>
                      <input value={this.state.address_line1} onChange={e => this.handleFormValueChange("address_line1", e)} type="text" className="form-control" placeholder="Enter your address" />
                    </div>
                    <div className="form-group">
                      <label className="pb-1">Address Line 2</label>
                      <input value={this.state.address_line2} onChange={e => this.handleFormValueChange("address_line2", e)} type="text" className="form-control" placeholder="Enter your address" />
                    </div>
                    <div className="form-group">
                      <label className="pb-1">City</label>
                      <input value={this.state.city} onChange={e => this.handleFormValueChange("city", e)} type="text" className="form-control" placeholder="Enter your city" />
                    </div>
                    <div className="form-group">
                      <label className="pb-1">State</label>
                      <input value={this.state.state} onChange={e => this.handleFormValueChange("state", e)} type="text" className="form-control" placeholder="Enter your state" />
                    </div>
                    <div className="form-group">
                      <label className="pb-1">Country</label>
                      <input value={this.state.country} onChange={e => this.handleFormValueChange("country", e)} type="text" className="form-control" placeholder="Enter your country" />
                    </div>
                    <div className="form-group">
                      <label className="pb-1">Zip Code</label>
                      <input value={this.state.zip_code} onChange={e => this.handleFormValueChange("zip_code", e)} type="text" className="form-control" placeholder="Enter your zip code" />
                    </div>
                    <div className="form-group">
                      <label className="pb-1">Billing Same as Address</label>
                      <input checked={this.state.billing_same} onChange={e => this.handleFormValueChange("billing_same", e)} type="checkbox" className="form-control" />
                    </div>
                    {!this.state.billing_same && (
                      <div>
                        <div className="form-group">
                          <label className="pb-1">Billing Address Line 1</label>
                          <input value={this.state.billing_address_line1} onChange={e => this.handleFormValueChange("billing_address_line1", e)} type="text" className="form-control" placeholder="Enter your billing address" />
                        </div>
                        <div className="form-group">
                          <label className="pb-1">Billing Address Line 2</label>
                          <input value={this.state.billing_address_line2} onChange={e => this.handleFormValueChange("billing_address_line2", e)} type="text" className="form-control" placeholder="Enter your billing address" />
                        </div>
                        <div className="form-group">
                          <label className="pb-1">Billing City</label>
                          <input value={this.state.billing_city} onChange={e => this.handleFormValueChange("billing_city", e)} type="text" className="form-control" placeholder="Enter your billing city" />
                        </div>
                        <div className="form-group">
                          <label className="pb-1">Billing State</label>
                          <input value={this.state.billing_state} onChange={e => this.handleFormValueChange("billing_state", e)} type="text" className="form-control" placeholder="Enter your billing state" />
                        </div>
                        <div className="form-group">
                          <label className="pb-1">Billing Zip Code</label>
                          <input value={this.state.billing_zip_code} onChange={e => this.handleFormValueChange("billing_zip_code", e)} type="text" className="form-control" placeholder="Enter your billing zip code" />
                        </div>
                        <div className="form-group">
                          <label className="pb-1">Billing Country</label>
                          <input value={this.state.billing_country} onChange={e => this.handleFormValueChange("billing_country", e)} type="text" className="form-control" placeholder="Enter your billing country" />
                        </div>
                      </div>
                    )}

                    <button className="btn btn-block btn-brand-02 border-radius mt-4 mb-3" disabled={this.state.disableBtn}>
                      {this.state.btnText}
                    </button>
                  </form>
                </div>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-md-8 col-lg-6">
                <div className="copyright-wrap small-text text-center mt-5 text-white">
                  <p className="mb-0">&copy; All rights reserved.</p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <PricingProtected subscribedPlan={this.state.subscribedPlan} email={this.state.email} onSubscriptionUpdate={(message, type) => this.setState({ modalIsOpen: true, modalMessage: message, modalType: type })} />
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={this.closeModal}
          style={{
            overlay: {
              background: "rgba(0, 0, 0, 0.75)",
              position: "fixed",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              zIndex: 1000,
            },
            content: {
              background: "white",
              padding: "2em",
              borderRadius: "10px",
              maxWidth: "500px",
              width: "100%",
              textAlign: "center",
              margin: "auto",
            },
          }}
        >
          <div style={{ color: modalType === "success" ? "green" : "red" }}>
            <h2>{modalType === "success" ? "Welcome!" : "Oops!"}</h2>
            <p>{modalMessage}</p>
            <button onClick={this.closeModal}>Close</button>
          </div>
        </Modal>
      </React.Fragment>
    );
  }
}

export default connect(state => ({}))(withRouter(ProfilePage));
