import React from 'react';
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoggedIn: false,
      userEmail: "",
    };
  }

  componentDidMount() {
    const accessToken = localStorage.getItem("accessToken");
    const userEmail = localStorage.getItem("userEmail");
    if (accessToken && userEmail) {
      this.setState({
        isLoggedIn: true,
        userEmail: userEmail,
      });
    }
  }

  logout = () => {
    // Clear tokens from localStorage
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("userEmail");
    localStorage.removeItem("idToken");

    // Redirect to the login page
    this.props.history.push("/login");
  };

  render() {
    const { isLoggedIn, userEmail } = this.state;
    return (
      <React.Fragment>
        <header className="header">
          <nav className="navbar navbar-expand-lg fixed-top bg-transparent">
            <div className="container d-flex justify-content-between align-items-center">
              <div className="d-flex align-items-center">
                <a className="navbar-brand" href="index.html">
                  <img
                    src={
                      this.props.isColorLogo && this.props.isColorLogo === true
                        ? "assets/img/logo-color.png"
                        : "assets/img/logo-white.png"
                    }
                    alt="logo"
                    className="img-fluid"
                    style={{ width: '170px', height: '44px' }}
                  />
                </a>
              </div>
              
              <div className="d-flex align-items-center">
                <button
                  className="navbar-toggler"
                  type="button"
                  data-toggle="collapse"
                  data-target="#navbarSupportedContent"
                  aria-controls="navbarSupportedContent"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <span className="ti-menu"></span>
                </button>

                <div className="collapse navbar-collapse h-auto" id="navbarSupportedContent">
                  <ul className="navbar-nav mx-auto menu">
                    <li><a href="#about" className="page-scroll">About</a></li>
                    <li><a href="#screenshots" className="page-scroll">Screenshots</a></li>
                    <li><a href="#process" className="page-scroll">Process</a></li>
                    <li><a href="#pricing" className="page-scroll">Pricing</a></li>
                    <li><a href="#contact" className="page-scroll">Contact</a></li>
                  </ul>
                </div>
              </div>
              
              <div className="d-flex align-items-center">
                {isLoggedIn ? (
                  <div className="d-flex align-items-center">
                    <span className="nav-link" style={{ color: 'white', marginRight: '10px' }}>
                      {userEmail}
                    </span>
                    <button 
                      className="btn btn-link nav-link" 
                      style={{ color: 'white', padding: 0 }} 
                      onClick={this.logout}
                    >
                      Logout
                    </button>
                  </div>
                ) : (
                  <Link to="/login" className="nav-link" style={{ color: 'white' }}>
                    <i className="ti-user" style={{ color: 'white' }}></i> Login
                  </Link>
                )}
              </div>
            </div>
          </nav>
        </header>
      </React.Fragment>
    );
  }
}

export default withRouter(connect()(Header));
