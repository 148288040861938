import React from "react";
import { connect } from "react-redux";
import { subscribe } from "../../actions/index";
import { Link } from "react-router-dom";  // Import Link component from react-router-dom
import _data from "../../data";

class HeroSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hero: {},
      email: "",
      disableBtn: false,
      btnText: "Subscribe"
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  changeBtnText = btnText => {
    this.setState({ btnText });
  };

  handleFormValueChange(inputName, event) {
    let stateValue = {};
    stateValue[inputName] =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    this.setState(stateValue);
  }

  handleSubmit(event) {
    event.preventDefault();

    // disable the button
    this.setState({ disableBtn: true });

    // get action
    const subscribeAction = subscribe(this.state);

    // Dispatch the contact from data
    this.props.dispatch(subscribeAction);

    // added delay to change button text to previous
    setTimeout(
      function() {
        // enable the button
        this.setState({ disableBtn: false });

        // change to button name
        this.changeBtnText("Subscribe");

        // get action again to update state
        const subscribeAction = subscribe(this.state);

        // Dispatch the contact from data
        this.props.dispatch(subscribeAction);

        // clear form data
        this.setState({
          email: ""
        });
      }.bind(this),
      3000
    );
  }

  componentDidMount() {
    /**
     * Your ajax will goes here to get data then call setState
     */

    this.setState({
      hero: _data.hero
    });
  }

  render() {
    return (
      <React.Fragment>
        <section className="pt-20 bg-image overflow-hidden" image-overlay="10">
          <div className="hero-bottom-shape-two" style={{
            backgroundImage: "url(assets/img/hero-bottom-shape-2.svg)",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "bottom center"
          }}></div>
          <div className="container">
            <div className="row align-items-center justify-content-center">
              <div className="col-md-12">
                <div className="hero-slider-content text-white py-5">
                  <h1 className="text-white">Best Way to Connect with Customers</h1>
                  <p className="lead">
                    Welcome to Peachy Assist, your ultimate AI-powered email assistant that revolutionizes customer communication. Here’s what Peachy Assist offers:
                  </p>
                  <div className="p-3 bg-white text-dark rounded">
                    <div className="row">
                      <div className="col-md-6 col-12">
                        <div className="features-single-wrap mb-sm-0 mb-md-5 mb-lg-5">
                          <span className="ti-email p-3 mr-4 mt-1 rounded-circle float-left"></span>
                          <div className="features-single-content d-block overflow-hidden">
                            <h5 className="mb-2">Email Monitoring</h5>
                            <p>
                              Monitors Gmail, Outlook, SMTP, and POP3.
                            </p>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-6 col-12">
                        <div className="features-single-wrap mb-sm-0 mb-md-5 mb-lg-5">
                          <span className="ti-pie-chart p-3 mr-4 mt-1 rounded-circle float-left"></span>
                          <div className="features-single-content d-block overflow-hidden">
                            <h5 className="mb-2">AI-Powered Insights</h5>
                            <p className="text-muted">
                              Provides real-time insights into customer interactions.
                            </p>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-6 col-12">
                        <div className="features-single-wrap mb-sm-0 mb-md-5 mb-lg-5">
                          <span className="ti-comments p-3 mr-4 mt-1 rounded-circle float-left"></span>
                          <div className="features-single-content d-block overflow-hidden">
                            <h5 className="mb-2">Human-Like Responses</h5>
                            <p className="text-muted">
                              Generates responses indistinguishable from human replies.
                            </p>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-6 col-12">
                        <div className="features-single-wrap mb-sm-0 mb-md-5 mb-lg-5">
                          <span className="ti-loop p-3 mr-4 mt-1 rounded-circle float-left"></span>
                          <div className="features-single-content d-block overflow-hidden">
                            <h5 className="mb-2">Continuous Learning</h5>
                            <p className="text-muted">
                              Learns from your business style and improves over time.
                            </p>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-6 col-12">
                        <div className="features-single-wrap mb-sm-0 mb-md-5 mb-lg-5">
                          <span className="ti-world p-3 mr-4 mt-1 rounded-circle float-left"></span>
                          <div className="features-single-content d-block overflow-hidden">
                            <h5 className="mb-2">Multilingual Communication</h5>
                            <p className="text-muted">
                              Supports seamless translations for global communication.
                            </p>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-6 col-12">
                        <div className="features-single-wrap mb-sm-0 mb-md-5 mb-lg-5">
                          <span className="ti-shopping-cart p-3 mr-4 mt-1 rounded-circle float-left"></span>
                          <div className="features-single-content d-block overflow-hidden">
                            <h5 className="mb-2">Automated Orders</h5>
                            <p className="text-muted">
                              Automatically generates orders with minimal input.
                            </p>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-6 col-12">
                        <div className="features-single-wrap mb-sm-0 mb-md-5 mb-lg-5">
                          <span className="ti-settings p-3 mr-4 mt-1 rounded-circle float-left"></span>
                          <div className="features-single-content d-block overflow-hidden">
                            <h5 className="mb-2">Easy Setup</h5>
                            <p className="text-muted">
                              Learns from your website for a quick setup.
                            </p>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-6 col-12">
                        <div className="features-single-wrap mb-sm-0 mb-md-5 mb-lg-5">
                          <span className="ti-user p-3 mr-4 mt-1 rounded-circle float-left"></span>
                          <div className="features-single-content d-block overflow-hidden">
                            <h5 className="mb-2">Manager Communication</h5>
                            <p className="text-muted">
                              Consults with a manager before responding.
                            </p>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-6 col-12">
                        <div className="mb-sm-0 mb-md-1 mb-lg-5">
                          <span className="ti-clipboard p-3 mr-4 mt-1 rounded-circle float-left"></span>
                          <div className="features-single-content d-block overflow-hidden">
                            <h5 className="mb-2">Data Collection</h5>
                            <p className="text-muted">
                              Collects and organizes customer data (birthdays, phone numbers, etc.).
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <p className="lead mt-4 text-white">
                    Transform your customer engagement with Peachy Assist, the smart assistant that grows with your business.
                  </p>
                  <div className="action-btns mt-3">
                    <a href="/profile" className="btn btn-brand-03 btn-rounded mr-3">Download Now <i className="fas fa-cloud-download-alt pl-2"></i></a>
                    <a href="https://www.youtube.com/watch?v=1APwq1df6Mw" className="popup-youtube btn btn-white btn-circle btn-icon"><i className="fas fa-play"></i> </a> <span className="pl-2"> Watch Now</span>
                  </div>
                  <div className="hero-counter mt-4">
                    <div className="row">
                      <div className="col-6 col-sm-4">
                        <Link to="/profile" className="text-white">{this.state.email}</Link>
                      </div>

                      <div className="col-6 col-sm-4">

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default connect(state => ({
  state
}))(HeroSection);
