  module.exports = {
    contact: {
      title: "Any questions?",
      description:
        "Give us a call or send an email. We endeavour to answer all enquiries within 24 hours on business days.",

      addressTitle: "Company Location",
      addressIcon: "fas fa-location-arrow",
      address: "Sarasota, Florida",
      emailTitle: "Email Address",
      emailIcon: "fas fa-envelope",
      email: "info@peachyassist.com"
    }
  };
