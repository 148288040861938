// src/api/api.js
import axios from 'axios';

const API_BASE_URL = 'https://odnifeo374.execute-api.us-east-2.amazonaws.com/dev/'; // Replace with your API base URL

const apiClient = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

// Public API call
export const apiCallPublic = (endpoint, data) => {
  return apiClient.post(endpoint, data);
};

// Authenticated API call
export const apiCall = (endpoint, data) => {
  const idToken = localStorage.getItem('idToken');
  const headers = idToken ? { Authorization: `Bearer ${idToken}` } : {};
  return apiClient.post(endpoint, data, { headers });
};
