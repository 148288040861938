import React from "react";

class Features extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    /**
     * Your ajax will goes here to get data then call setState
     */
  }

  render() {
    return (
      <React.Fragment>
        <div id="features" className={"feature-section ptb-100 " + (this.props.isGray && this.props.isGray === true ? 'gray-light-bg' : '')}>
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-9 col-lg-9">
                <div className="section-heading text-center mb-5">
                  <h2>Peachy Assist AI Features</h2>
                  <p>
                    Discover the cutting-edge AI technology that powers PeachyXXXX Assist. Our app is designed to revolutionize customer relations by providing insightful solutions and seamless communication capabilities.
                  </p>
                </div>
              </div>
            </div>
            <div className="row align-items-center justify-content-md-center">
              <div className="col-lg-4 col-md-12">
                <div className="row">
                  <div className="col-12">
                    <div className="d-flex align-items-start mb-sm-0 mb-md-3 mb-lg-3">
                      <span className="ti-world icon-size-md color-secondary mr-4"></span>
                      <div className="icon-text">
                        <h5 className="mb-2">Multilingual Support</h5>
                        <p>Language is not an issue. Communicate with the whole world in their language seamlessly.</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="d-flex align-items-start mb-sm-0 mb-md-3 mb-lg-3">
                      <span className="ti-comments icon-size-md color-secondary mr-4"></span>
                      <div className="icon-text">
                        <h5 className="mb-2">Not a Chatbot</h5>
                        <p>Generated responses brewed from your company database, ensuring accuracy and relevance.</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="d-flex align-items-start mb-sm-0 mb-md-3 mb-lg-3">
                      <span className="ti-server icon-size-md color-secondary mr-4"></span>
                      <div className="icon-text">
                        <h5 className="mb-2">Supports Large Data</h5>
                        <p>Capable of handling and training on large datasets for more precise and tailored insights.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-5 d-none d-sm-none d-md-block d-lg-block">
                <div className="position-relative pb-md-5 py-lg-0">
                  <img alt="app screen" src="assets/img/app-mobile-image.png" className="img-center img-fluid" />
                </div>
              </div>
              <div className="col-lg-4 col-md-12">
                <div className="row">
                  <div className="col-12">
                    <div className="d-flex align-items-start mb-sm-0 mb-md-3 mb-lg-3">
                      <span className="ti-headphone-alt icon-size-md color-secondary mr-4"></span>
                      <div className="icon-text">
                        <h5 className="mb-2">Insightful Suggestions</h5>
                        <p>Provides actionable insights and solutions to improve your customer relations and business strategies.</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="d-flex align-items-start mb-sm-0 mb-md-3 mb-lg-3">
                      <span className="ti-light-bulb icon-size-md color-secondary mr-4"></span>
                      <div className="icon-text">
                        <h5 className="mb-2">Advanced AI Solutions</h5>
                        <p>Employs state-of-the-art AI algorithms to analyze customer interactions and suggest improvements.</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="d-flex align-items-start mb-sm-0 mb-md-3 mb-lg-3">
                      <span className="ti-reload icon-size-md color-secondary mr-4"></span>
                      <div className="icon-text">
                        <h5 className="mb-2">Continuous Learning</h5>
                        <p>Continuously learns from interactions to provide better support and more accurate responses over time.</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="d-flex align-items-start mb-sm-0 mb-md-3 mb-lg-3">
                      <span className="ti-dashboard icon-size-md color-secondary mr-4"></span>
                      <div className="icon-text">
                        <h5 className="mb-2">Performance Tracking</h5>
                        <p>Tracks performance metrics to ensure the effectiveness of customer communication and support strategies.</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="d-flex align-items-start mb-sm-0 mb-md-3 mb-lg-3">
                      <span className="ti-user icon-size-md color-secondary mr-4"></span>
                      <div className="icon-text">
                        <h5 className="mb-2">Personalized Support</h5>
                        <p>Offers personalized customer support based on the unique needs and preferences of your clients.</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="d-flex align-items-start mb-sm-0 mb-md-3 mb-lg-3">
                      <span className="ti-lock icon-size-md color-secondary mr-4"></span>
                      <div className="icon-text">
                        <h5 className="mb-2">Data Security</h5>
                        <p>Ensures the security and privacy of customer data with robust security measures and protocols.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Features;
