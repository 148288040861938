import React from "react";
import { connect } from "react-redux";
import { apiCallPublic } from "../../api"; // Adjust the import based on your project structure
import { withRouter } from "react-router-dom";

class HeroSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      first_name: "",
      last_name: "",
      email: "",
      company: "",
      password: "",
      phone: "",
      address_line1: "",
      address_line2: "",
      billing_same: false,
      city: "",
      state: "",
      country: "",
      zip_code: "",
      billing_address_line1: "",
      billing_address_line2: "",
      billing_city: "",
      billing_state: "",
      billing_zip_code: "",
      billing_country: "",
      disableBtn: false,
      btnText: "Sign Up",
      errorMessage: "",
      successMessage: "",
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleFormValueChange = this.handleFormValueChange.bind(this);
    this.message = this.message.bind(this);
  }

  changeBtnText = (btnText) => {
    this.setState({ btnText });
  };

  handleFormValueChange(inputName, event) {
    let stateValue = {};
    stateValue[inputName] =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    this.setState(stateValue);
  }

  validatePhoneNumber(phone) {
    const phoneRegex = /^\+\d{11,14}$/;
    return phoneRegex.test(phone);
  }

  handleSubmit(event) {
    event.preventDefault();

    const {
      first_name,
      last_name,
      email,
      company,
      password,
      phone,
    } = this.state;

    if (
      !first_name ||
      !last_name ||
      !email ||
      !company ||
      !password
    ) {
      this.setState({ errorMessage: "Please fill in all required fields." });
      this.message(true);
      return;
    }

    if (phone && !this.validatePhoneNumber(phone)) {
      this.setState({ errorMessage: "Please enter a valid phone number in the format +16467892336." });
      this.message(true);
      return;
    }

    this.setState({ disableBtn: true, btnText: "Signing up..." });

    apiCallPublic({
      params: {
        first_name,
        last_name,
        email,
        company,
        password,
        phone,
        address_line1: this.state.address_line1,
        address_line2: this.state.address_line2,
        billing_same: this.state.billing_same,
        city: this.state.city,
        state: this.state.state,
        country: this.state.country,
        zip_code: this.state.zip_code,
        billing_address_line1: this.state.billing_address_line1,
        billing_address_line2: this.state.billing_address_line2,
        billing_city: this.state.billing_city,
        billing_state: this.state.billing_state,
        billing_zip_code: this.state.billing_zip_code,
        billing_country: this.state.billing_country,
      },
    })
      .then((response) => {
        console.log (response)
        if (response.data.data && response.data.data.status === "Success") {
          this.setState({
            disableBtn: false,
            btnText: "Sign Up",
            successMessage: "Signup successful!",
            errorMessage: "",
          });
          this.message(false);
          // Redirect to the login page after successful signup
          this.props.history.push("/login");
        } else {
          this.setState({
            disableBtn: false,
            btnText: "Sign Up",
            errorMessage: "There was an error with the signup process. Please try again.",
          });
          this.message(true);
        }
      })
      .catch((error) => {
        console.error("Signup error:", error);
        this.setState({
          disableBtn: false,
          btnText: "Sign Up",
          errorMessage: "There was an error with the signup process. Please try again.",
        });
        this.message(true);
      });
  }

  message(error) {
    const messageBox = document.querySelector('.login-signup-wrap .message-box');
    if (messageBox.classList.contains("d-none")) {
      messageBox.classList.remove("d-none");
    }
    messageBox.classList.add("d-block");
    if (error) {
      if (messageBox.classList.contains("alert-success")) {
        messageBox.classList.remove("alert-success");
      }
      messageBox.classList.add("alert-danger");
      messageBox.innerHTML = this.state.errorMessage;
    } else {
      if (messageBox.classList.contains("alert-danger")) {
        messageBox.classList.remove("alert-danger");
      }
      messageBox.classList.add("alert-success");
      messageBox.innerHTML = this.state.successMessage;
    }
  }

  render() {
    return (
      <React.Fragment>
        <section className="page-header-section ptb-100 bg-image full-height" image-overlay="8">
          <div className="background-image-wraper" style={{
            backgroundImage: "url(assets/img/cta-bg.jpg)",
            opacity: 1
          }}></div>
          <div className="container">
            <div className="row align-items-center justify-content-center">
              <div className="col-12 col-md-8 col-lg-6">
                <div className="login-signup-wrap p-5 gray-light-bg rounded shadow">
                  <div className="login-signup-header text-center">
                    <a href="/"><img src="assets/img/logo-color.png" className="img-fluid mb-3" alt="Logo" /></a>
                    <h4 className="mb-5">Create Your Account</h4>
                  </div>
                  <div className="message-box d-none alert alert-success"></div>
                  <form className="login-signup-form" onSubmit={this.handleSubmit}>
                    <div className="form-group">
                      <label className="pb-1">First Name</label>
                      <input value={this.state.first_name} onChange={e => this.handleFormValueChange("first_name", e)} type="text" className="form-control" placeholder="Enter your first name" />
                    </div>
                    <div className="form-group">
                      <label className="pb-1">Last Name</label>
                      <input value={this.state.last_name} onChange={e => this.handleFormValueChange("last_name", e)} type="text" className="form-control" placeholder="Enter your last name" />
                    </div>
                    <div className="form-group">
                      <label className="pb-1">Email Address</label>
                      <input value={this.state.email} onChange={e => this.handleFormValueChange("email", e)} type="email" className="form-control" placeholder="name@address.com" />
                    </div>
                    <div className="form-group">
                      <label className="pb-1">Company</label>
                      <input value={this.state.company} onChange={e => this.handleFormValueChange("company", e)} type="text" className="form-control" placeholder="Enter your company" />
                    </div>
                    <div className="form-group">
                      <label className="pb-1">Password</label>
                      <input value={this.state.password} onChange={e => this.handleFormValueChange("password", e)} type="password" className="form-control" placeholder="Enter your password" />
                    </div>
                    <div className="form-group">
                      <label className="pb-1">Phone</label>
                      <input value={this.state.phone} onChange={e => this.handleFormValueChange("phone", e)} type="text" className="form-control" placeholder="Enter your phone number" />
                    </div>
                    <div className="form-group">
                      <label className="pb-1">Address Line 1</label>
                      <input value={this.state.address_line1} onChange={e => this.handleFormValueChange("address_line1", e)} type="text" className="form-control" placeholder="Enter your address" />
                    </div>
                    <div className="form-group">
                      <label className="pb-1">Address Line 2</label>
                      <input value={this.state.address_line2} onChange={e => this.handleFormValueChange("address_line2", e)} type="text" className="form-control" placeholder="Enter your address" />
                    </div>
                    <div className="form-group">
                      <label className="pb-1">City</label>
                      <input value={this.state.city} onChange={e => this.handleFormValueChange("city", e)} type="text" className="form-control" placeholder="Enter your city" />
                    </div>
                    <div className="form-group">
                      <label className="pb-1">State</label>
                      <input value={this.state.state} onChange={e => this.handleFormValueChange("state", e)} type="text" className="form-control" placeholder="Enter your state" />
                    </div>
                    <div className="form-group">
                      <label className="pb-1">Country</label>
                      <input value={this.state.country} onChange={e => this.handleFormValueChange("country", e)} type="text" className="form-control" placeholder="Enter your country" />
                    </div>
                    <div className="form-group">
                      <label className="pb-1">Zip Code</label>
                      <input value={this.state.zip_code} onChange={e => this.handleFormValueChange("zip_code", e)} type="text" className="form-control" placeholder="Enter your zip code" />
                    </div>
                    <div className="form-group">
                      <label className="pb-1">Billing Same as Address</label>
                      <input checked={this.state.billing_same} onChange={e => this.handleFormValueChange("billing_same", e)} type="checkbox" className="form-control" />
                    </div>
                    {!this.state.billing_same && (
                      <div>
                        <div className="form-group">
                          <label className="pb-1">Billing Address Line 1</label>
                          <input value={this.state.billing_address_line1} onChange={e => this.handleFormValueChange("billing_address_line1", e)} type="text" className="form-control" placeholder="Enter your billing address" />
                        </div>
                        <div className="form-group">
                          <label className="pb-1">Billing Address Line 2</label>
                          <input value={this.state.billing_address_line2} onChange={e => this.handleFormValueChange("billing_address_line2", e)} type="text" className="form-control" placeholder="Enter your billing address" />
                        </div>
                        <div className="form-group">
                          <label className="pb-1">Billing City</label>
                          <input value={this.state.billing_city} onChange={e => this.handleFormValueChange("billing_city", e)} type="text" className="form-control" placeholder="Enter your billing city" />
                        </div>
                        <div className="form-group">
                          <label className="pb-1">Billing State</label>
                          <input value={this.state.billing_state} onChange={e => this.handleFormValueChange("billing_state", e)} type="text" className="form-control" placeholder="Enter your billing state" />
                        </div>
                        <div className="form-group">
                          <label className="pb-1">Billing Zip Code</label>
                          <input value={this.state.billing_zip_code} onChange={e => this.handleFormValueChange("billing_zip_code", e)} type="text" className="form-control" placeholder="Enter your billing zip code" />
                        </div>
                        <div className="form-group">
                          <label className="pb-1">Billing Country</label>
                          <input value={this.state.billing_country} onChange={e => this.handleFormValueChange("billing_country", e)} type="text" className="form-control" placeholder="Enter your billing country" />
                        </div>
                      </div>
                    )}

                    <button className="btn btn-block btn-brand-02 border-radius mt-4 mb-3" disabled={this.state.disableBtn}>
                      {this.state.btnText}
                    </button>
                  </form>
                  <div className="other-login-signup my-3">
                    <div className="or-login-signup text-center">
                      <strong>Or Sign Up With</strong>
                    </div>
                  </div>
                  <ul className="list-inline social-login-signup text-center">
                    <li className="list-inline-item my-1">
                      <a href="/#" className="btn btn-facebook"><i className="fab fa-facebook-f pr-1"></i> Facebook</a>
                    </li>
                    <li className="list-inline-item my-1">
                      <a href="/#" className="btn btn-google"><i className="fab fa-google pr-1"></i> Google</a>
                    </li>
                    <li className="list-inline-item my-1">
                      <a href="/#" className="btn btn-twitter"><i className="fab fa-twitter pr-1"></i> Twitter</a>
                    </li>
                  </ul>
                  <p className="text-center mb-0">Already have an account? <a href="login">Login</a></p>
                </div>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-md-8 col-lg-6">
                <div className="copyright-wrap small-text text-center mt-5 text-white">
                  <p className="mb-0">&copy; All rights reserved.</p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default connect(state => ({}))(withRouter(HeroSection));
