import React from "react";

class Feature extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    /**
     * Your ajax will go here to get data then call setState
     */
  }

  render() {
    return (
      <React.Fragment>
        <section className="position-relative feature-section ptb-100">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-12 col-lg-10">
                <div className="section-heading text-center mb-5">
                  <h2>Revolutionize Your Business with AI</h2>
                  <p>
                    At Peachy Assist, our mission is to make AI accessible for small and mid-sized businesses. Our state-of-the-art prompting technology harnesses the power of artificial intelligence to transform customer communication and provide actionable insights.
                  </p>
                </div>
              </div>
            </div>
            <div className="row align-items-center mb-5">
              <div className="col-lg-6 col-md-12 order-lg-1 order-2">
                <div className="bg-white p-4 rounded">
                  <div className="d-flex align-items-start mb-3">
                    <span className="ti-world icon-size-md color-secondary mr-3"></span>
                    <div className="icon-text">
                      <h5 className="mb-2">Multilingual Support</h5>
                      <p>Communicate globally without language barriers. Our AI seamlessly translates messages in real-time, allowing you to interact with customers worldwide in their native languages, thus expanding your market reach effortlessly.</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-12 order-lg-2 order-1">
                <img src="assets/img/multilingual-support.png" alt="Multilingual Support" className="img-fluid" />
              </div>
            </div>

            <div className="row align-items-center mb-5">
              <div className="col-lg-6 col-md-12">
                <img src="assets/img/intelligent-responses.png" alt="Intelligent Responses" className="img-fluid" />
              </div>
              <div className="col-lg-6 col-md-12">
                <div className="bg-white p-4 rounded">
                  <div className="d-flex align-items-start mb-3">
                    <span className="ti-headphone-alt icon-size-md color-secondary mr-3"></span>
                    <div className="icon-text">
                      <h5 className="mb-2">Intelligent Responses</h5>
                      <p>Our AI generates responses from your company’s unique database, ensuring every interaction is personalized and contextually accurate. This goes beyond traditional chatbots, delivering responses that reflect your business's voice and style.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row align-items-center mb-5">
              <div className="col-lg-6 col-md-12 order-lg-1 order-2">
                <div className="bg-white p-4 rounded">
                  <div className="d-flex align-items-start mb-3">
                    <span className="ti-server icon-size-md color-secondary mr-3"></span>
                    <div className="icon-text">
                      <h5 className="mb-2">Large Data Handling</h5>
                      <p>Peachy Assist supports extensive datasets for training, ensuring precise insights. Unlike competitors, we can process your entire company’s data without limitations, allowing for comprehensive AI training and more accurate outputs.</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-12 order-lg-2 order-1">
                <img src="assets/img/large-data-handling.png" alt="Large Data Handling" className="img-fluid" />
              </div>
            </div>

            <div className="row align-items-center mb-5">
              <div className="col-lg-6 col-md-12">
                <img src="assets/img/insightful-suggestions.png" alt="Insightful Suggestions" className="img-fluid" />
              </div>
              <div className="col-lg-6 col-md-12">
                <div className="bg-white p-4 rounded">
                  <div className="d-flex align-items-start mb-3">
                    <span className="ti-light-bulb icon-size-md color-secondary mr-3"></span>
                    <div className="icon-text">
                      <h5 className="mb-2">Insightful Suggestions</h5>
                      <p>Our AI doesn’t just respond—it analyzes interactions to provide actionable insights that enhance customer relationships and inform business strategies. It identifies trends, flags potential issues, and suggests improvements proactively.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row align-items-center mb-5">
              <div className="col-lg-6 col-md-12 order-lg-1 order-2">
                <div className="bg-white p-4 rounded">
                  <div className="d-flex align-items-start mb-3">
                    <span className="ti-shield icon-size-md color-secondary mr-3"></span>
                    <div className="icon-text">
                      <h5 className="mb-2">Data Security</h5>
                      <p>Ensuring robust protection and privacy for all customer data is our top priority. Peachy Assist adheres to the highest security standards, keeping your data safe and compliant with regulations.</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-12 order-lg-2 order-1">
                <img src="assets/img/data-security.png" alt="Data Security" className="img-fluid" />
              </div>
            </div>

            <div className="row align-items-center mb-5">
              <div className="col-lg-6 col-md-12">
                <img src="assets/img/performance-tracking.png" alt="Performance Tracking" className="img-fluid" />
              </div>
              <div className="col-lg-6 col-md-12">
                <div className="bg-white p-4 rounded">
                  <div className="d-flex align-items-start mb-3">
                    <span className="ti-pie-chart icon-size-md color-secondary mr-3"></span>
                    <div className="icon-text">
                      <h5 className="mb-2">Performance Tracking</h5>
                      <p>Monitor key metrics to evaluate the effectiveness of your communication strategies. Peachy Assist provides detailed analytics and reports, helping you understand performance and identify areas for improvement.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row align-items-center mb-5">
              <div className="col-lg-6 col-md-12 order-lg-1 order-2">
                <div className="bg-white p-4 rounded">
                  <div className="d-flex align-items-start mb-3">
                    <span className="ti-hand-point-up icon-size-md color-secondary mr-3"></span>
                    <div className="icon-text">
                      <h5 className="mb-2">Personalized Support</h5>
                      <p>Our AI tailors support based on individual client needs and preferences. It remembers past interactions and preferences, providing a consistent and personalized customer experience every time.</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-12 order-lg-2 order-1">
                <img src="assets/img/personalized-support.png" alt="Personalized Support" className="img-fluid" />
              </div>
            </div>

            <div className="row align-items-center">
              <div className="col-lg-6 col-md-12">
                <img src="assets/img/continuous-learning.png" alt="Continuous Learning" className="img-fluid" />
              </div>
              <div className="col-lg-6 col-md-12">
                <div className="bg-white p-4 rounded">
                  <div className="d-flex align-items-start mb-3">
                    <span className="ti-loop icon-size-md color-secondary mr-3"></span>
                    <div className="icon-text">
                      <h5 className="mb-2">Continuous Learning</h5>
                      <p>Peachy Assist’s AI learns and adapts from every interaction, continuously improving its accuracy and relevance. This ongoing learning process ensures that the AI becomes more effective and personalized over time.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row justify-content-center mt-4">
              <div className="col-md-8 text-center">
                <div className="action-btns">
                  <a href="/signup" className="btn btn-brand-02 mr-3">Get Started Now</a>
                  <a href="/#process" className="btn btn-outline-brand-02">Learn More</a>
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default Feature;
