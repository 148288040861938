import React from "react";
import { connect } from "react-redux";
import _data from "../../data";

class PromoSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      promo: {}
    };
  }

  componentDidMount() {
    /**
     * Your ajax will go here to get data then call setState
     */

    this.setState({
      promo: _data.promo
    });
  }

  render() {
    return (
      <React.Fragment>
        <section className="promo-section ptb-100">
          <div className="container">
            <div className="row">
              <div className="col-md-6 col-lg-3">
                <div className="card border-0 single-promo-card single-promo-hover p-2 mt-4 shadow">
                  <div className="card-body">
                    <div className="pb-2">
                      <span className="fas fa-smile icon-size-md color-secondary"></span>
                    </div>
                    <div className="pt-2 pb-3">
                      <h5>User-Friendly</h5>
                      <p className="mb-0">
                        Peachy ensures a smooth and effortless experience with an intuitive interface.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-3">
                <div className="card border-0 single-promo-card single-promo-hover p-2 mt-4 shadow">
                  <div className="card-body">
                    <div className="pb-2">
                      <span className="fas fa-window-restore icon-size-md color-secondary"></span>
                    </div>
                    <div className="pt-2 pb-3">
                      <h5>Responsive Design</h5>
                      <p className="mb-0">
                        Peachy is optimized for all devices, whether iOS or Android, across all sizes.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-3">
                <div className="card border-0 single-promo-card single-promo-hover p-2 mt-4 shadow">
                  <div className="card-body">
                    <div className="pb-2">
                      <span className="fas fa-sync-alt icon-size-md color-secondary"></span>
                    </div>
                    <div className="pt-2 pb-3">
                      <h5>Scalable Solutions</h5>
                      <p className="mb-0">
                        Peachy handles large datasets effortlessly, ensuring optimal performance.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-3">
                <div className="card border-0 single-promo-card single-promo-hover p-2 mt-4 shadow">
                  <div className="card-body">
                    <div className="pb-2">
                      <span className="fas fa-bezier-curve icon-size-md color-secondary"></span>
                    </div>
                    <div className="pt-2 pb-3">
                      <h5>Accessible AI</h5>
                      <p className="mb-0">
                        Peachy empowers businesses with AI without the need for large teams.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default connect(state => ({
  state
}))(PromoSection);
