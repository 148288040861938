import React from "react";
import { connect } from "react-redux";
import { apiCallPublic } from "../../api"; // Adjust the import based on your project structure
import { withRouter } from "react-router-dom"; // Import withRouter to enable history

class ConfirmCode extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      code: "",
      disableBtn: false,
      btnText: "Confirm Code",
      errorMessage: "",
      successMessage: "",
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleFormValueChange = this.handleFormValueChange.bind(this);
    this.message = this.message.bind(this);
  }

  changeBtnText = (btnText) => {
    this.setState({ btnText });
  };

  handleFormValueChange(inputName, event) {
    let stateValue = {};
    stateValue[inputName] = event.target.value;
    this.setState(stateValue);
  }

  handleSubmit(event) {
    event.preventDefault();

    const { email, code } = this.state;

    if (!email || !code) {
      this.setState({ errorMessage: "Please fill in all fields." });
      this.message(true);
      return;
    }

    this.setState({ disableBtn: true, btnText: "Confirming..." });

    apiCallPublic({
      params: {
        user_name: email,
        code: code
      }
    })
      .then((response) => {
        if (response.data.data && response.data.data.ResponseMetadata) {
          this.setState({
            disableBtn: false,
            btnText: "Confirm Code",
            successMessage: "Code confirmed successfully!",
            errorMessage: "",
          });
          this.message(false);
          // Redirect to the login page or another page after successful confirmation
          this.props.history.push('/login'); // Adjust the path as needed
        } else {
          this.setState({
            disableBtn: false,
            btnText: "Confirm Code",
            errorMessage: "Invalid code. Please try again.",
          });
          this.message(true);
        }
      })
      .catch((error) => {
        console.error("Confirm code error:", error);
        this.setState({
          disableBtn: false,
          btnText: "Confirm Code",
          errorMessage: "There was an error confirming the code. Please try again.",
        });
        this.message(true);
      });
  }

  message(error) {
    const messageBox = document.querySelector('.login-signup-wrap .message-box');
    if (messageBox.classList.contains("d-none")) {
      messageBox.classList.remove("d-none");
    }
    messageBox.classList.add("d-block");
    if (error) {
      if (messageBox.classList.contains("alert-success")) {
        messageBox.classList.remove("alert-success");
      }
      messageBox.classList.add("alert-danger");
      messageBox.innerHTML = this.state.errorMessage;
    } else {
      if (messageBox.classList.contains("alert-danger")) {
        messageBox.classList.remove("alert-danger");
      }
      messageBox.classList.add("alert-success");
      messageBox.innerHTML = this.state.successMessage;
    }
  }

  render() {
    return (
      <React.Fragment>
        <section className="page-header-section ptb-100 bg-image full-height" image-overlay="8">
          <div className="background-image-wraper" style={{ backgroundImage: "url(assets/img/cta-bg.jpg)", opacity: 1 }}></div>
          <div className="container">
            <div className="row align-items-center justify-content-center">
              <div className="col-12 col-md-8 col-lg-6">
                <div className="login-signup-wrap p-5 gray-light-bg rounded shadow">
                  <div className="login-signup-header text-center">
                    <a href="/"><img src="assets/img/logo-color.png" className="img-fluid mb-3" alt="Logo" /></a>
                    <h4 className="mb-5">Enter Confirmation Code</h4>
                  </div>
                  <div className="message-box d-none alert alert-success"></div>
                  <form className="login-signup-form" onSubmit={this.handleSubmit}>
                    <div className="form-group">
                      <label className="pb-1">Email Address</label>
                      <div className="input-group input-group-merge">
                        <div className="input-icon">
                          <span className="ti-email"></span>
                        </div>
                        <input value={this.state.email} onChange={(e) => this.handleFormValueChange("email", e)} type="email" className="form-control" placeholder="name@address.com" />
                      </div>
                    </div>
                    <div className="form-group">
                      <label className="pb-1">Confirmation Code</label>
                      <div className="input-group input-group-merge">
                        <div className="input-icon">
                          <span className="ti-key"></span>
                        </div>
                        <input value={this.state.code} onChange={(e) => this.handleFormValueChange("code", e)} type="text" className="form-control" placeholder="Enter the code" />
                      </div>
                    </div>

                    {this.state.errorMessage && (
                      <div className="alert alert-danger" role="alert">
                        {this.state.errorMessage}
                      </div>
                    )}

                    <button className="btn btn-block btn-brand-02 border-radius mt-4 mb-3" disabled={this.state.disableBtn}>
                      {this.state.btnText}
                    </button>
                  </form>
                  <p className="text-center mb-0">Didn't receive a code? <a href="/resend-code">Resend Code</a></p>
                </div>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-md-8 col-lg-6">
                <div className="copyright-wrap small-text text-center mt-5 text-white">
                  <p className="mb-0">&copy; All rights reserved.</p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default withRouter(connect()(ConfirmCode));
