import React from "react";
import _data from "../../data";

export default class Pricing extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      price: {}
    };
  }

  componentDidMount() {
    this.setState({
      price: _data.price
    });
  }

  render() {
    return (
      <React.Fragment>
        <section id="pricing" className={"pricing-section ptb-100 " + (this.props.isWhite && this.props.isWhite === true ? '' : 'gray-light-bg')}>
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-9 col-lg-8">
                <div className="section-heading text-center mb-4">
                  <h2>Packages</h2>
                  <p>
                    Tailored AI solutions at a competitive price. Empower your business with intelligent automation and personalized customer interactions through Peachy Assist.
                  </p>
                </div>
              </div>
            </div>
            <div className="row align-items-center justify-content-md-center justify-content-center">
              <div className="col-12">
                <div className="d-flex justify-content-center text-center">
                  <label className="pricing-switch-wrap">
                    <span className="beforeinput year-switch text-success">
                      Monthly
                    </span>
                    <input type="checkbox" className="d-none" id="js-contcheckbox" />
                    <span className="switch-icon"></span>
                    <span className="afterinput year-switch">
                      Yearly
                    </span>
                  </label>
                </div>
              </div>

              {this.renderPricingCard("Free Plan", "$0", "$0", "price-img-1.png", ["Single User", "10 messages / day", "Email Support"])}
              {this.renderPricingCard("Basic", "$65.45", "$375.99", "price-img-2.png", ["Up to 3 agents", "100 messages / day", "Email Support / Limited Phone Support"])}
              {this.renderPricingCard("Platinum Plan", "$379.45", "$1875.99", "price-img-3.png", ["Up to 50 agents", "1000 messages / day", "Email Support - Phone Support (EST)"])}
              {this.renderPricingCard("Enterprise Plan", "$975.45", "$4199.99", "price-img-4.png", ["Up tp 1000 agents", "Up to 10000", "24/7 Phone Support - Email Support"])}

              <div className="col-12">
                <div className="support-cta text-center mt-5">
                  <h5 className="mb-1"><span className="ti-headphone-alt color-primary mr-3"></span>We're Here to Help You</h5>
                  <p>Have some questions? <a href="/#">Chat with us now</a>, or <a href="/#">send us an email</a> to get in touch.</p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }

  renderPricingCard(planName, monthlyPrice, yearlyPrice, imgFileName, features) {
    const imgSrc = `/assets/img/${imgFileName}`;
    
    return (
      <div className="col-lg-4 col-md-6 col-sm-8">
        <div className="text-center bg-white single-pricing-pack mt-4">
          <div className="price-img pt-4">
            <img src={imgSrc} alt={planName} width="120" className="img-fluid" />
          </div>
          <div className="py-4 border-0 pricing-header">
            <div className="price text-center mb-0 monthly-price color-secondary" style={{display: "block"}}>{monthlyPrice}<span></span></div>
            <div className="price text-center mb-0 yearly-price color-secondary" style={{display: "none"}}>{yearlyPrice}<span></span></div>
          </div>
          <div className="price-name">
            <h5 className="mb-0">{planName}</h5>
          </div>
          <div className="pricing-content">
            <ul className="list-unstyled mb-4 pricing-feature-list">
              {features.map((feature, index) => (
                <li key={index}><span>{feature}</span></li>
              ))}
            </ul>
            <a href="/#" className="btn btn-outline-brand-02 btn-rounded mb-3" target="_blank">Purchase now</a>
          </div>
        </div>
      </div>
    );
  }
}
