import React, { Component } from "react";
import _data from "../../data";

class Testimonial extends Component {
  constructor(props) {
    super(props);
    this.state = {
      testimonial: {}
    };
  }

  componentDidMount() {
    /**
     * Your ajax will goes here to get data then call setState
     */
    this.setState({
      testimonial: _data.testimonial
    });
  }

  render() {
    return (
      <React.Fragment>
        <section className="position-relative gradient-bg ptb-100">
          <div className="container">
              <div className="row align-items-center">
                  <div className="col-md-6 col-lg-5 mb-4 mb-sm-4 mb-md-0 mb-lg-0">
                      <div className="testimonial-heading text-white">
                          <h2 className="text-white">What Our Client Say About Peachy:</h2>
                          <p>Our customers come from all walks of life, using Peachy Assist to simplify their communication and enhance their work. Here's what they have to say about how Peachy has made a difference for them.</p>
                      </div>
                  </div>
                  <div className="col-md-6 col-lg-6">
                      <div className="testimonial-content-wrap">
                          <img src="assets/img/testimonial-arrow-top.png" className="img-fluid testimonial-tb-shape shape-top" alt="testimonial shape" />
                          <div className="owl-carousel owl-theme client-testimonial-1 dot-indicator testimonial-shape">
                              <div className="item">
                                  <div className="testimonial-quote-wrap">
                                      <div className="media author-info mb-3">
                                          <div className="author-img mr-3">
                                              <img src="assets/img/client/1.jpg" alt="client" className="img-fluid" />
                                          </div>
                                          <div className="media-body text-white">
                                              <h5 className="mb-0 text-white">Irene Bakush</h5>
                                              <span>Dream Weddings CEO</span>
                                          </div>
                                          <i className="fas fa-quote-right text-white"></i>
                                      </div>
                                      <div className="client-say text-white">
                                          <p>Peachy Assist boosted my productivity, and I never knew AI could be so helpful—without any technical knowledge, I was able to adopt AI into my company and save hours.</p>
                                      </div>
                                  </div>
                              </div>
                              <div className="item">
                                  <div className="testimonial-quote-wrap">
                                      <div className="media author-info mb-3">
                                          <div className="author-img mr-3">
                                              <img src="assets/img/client/2.jpg" alt="client" className="img-fluid" />
                                          </div>
                                          <div className="media-body text-white">
                                              <h5 className="mb-0 text-white">Sam</h5>
                                              <span>Mechanic / Busines Owner</span>
                                          </div>
                                          <i className="fas fa-quote-right text-white"></i>
                                      </div>
                                      <div className="client-say text-white">
                                          <p>Peachy Assist has transformed how I communicate with customers. It quickly generates responses and even helps diagnose car issues just from emails. It's a game-changer for my shop!</p>
                                      </div>
                                  </div>
                              </div>
                              <div className="item">
                                  <div className="testimonial-quote-wrap">
                                      <div className="media author-info mb-3">
                                          <div className="author-img mr-3">
                                              <img src="assets/img/client/3.jpg" alt="client" className="img-fluid" />
                                          </div>
                                          <div className="media-body text-white">
                                              <h5 className="mb-0 text-white">Hatice</h5>
                                              <span>Attorney</span>
                                          </div>
                                          <i className="fas fa-quote-right text-white"></i>
                                      </div>
                                      <div className="client-say text-white">
                                          <p>Peachy Assist has made it effortless for me to communicate with clients facing immigration issues, regardless of their language. Its translation abilities ensure I can focus on providing the help they need without any barriers.</p>
                                      </div>
                                  </div>
                              </div>
                              <div className="item">
                                  <div className="testimonial-quote-wrap">
                                      <div className="media author-info mb-3">
                                          <div className="author-img mr-3">
                                              <img src="assets/img/client/4.jpg" alt="client" className="img-fluid" />
                                          </div>
                                          <div className="media-body text-white">
                                              <h5 className="mb-0 text-white">Sonya Dogru</h5>
                                              <span>Photographer</span>
                                          </div>
                                          <i className="fas fa-quote-right text-white"></i>
                                      </div>
                                      <div className="client-say text-white">
                                          <p>I m running a custom t-shirt business and it has has never been smoother thanks to Peachy Assist. I love how orders are automatically created as I naturally communicate with customers, and all their data is gathered seamlessly. It’s made my workflow so much more efficient!</p>
                                      </div>
                                  </div>
                              </div>
                          </div>
                          <img src="assets/img/testimonial-arrow-bottom.png" className="img-fluid testimonial-tb-shape shape-bottom" alt="testimonial shape" />
                      </div>
                  </div>
              </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default Testimonial;
