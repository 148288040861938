import React, { Component } from "react";

import HeroSection from "../components/HeroSection/HeroSectionProfile";

class Profile extends Component {
    render() {
    return (
        <React.Fragment>
        <div className="main">
            <HeroSection />
        </div>  
        </React.Fragment>
    );
    }
}

export default Profile;
