import React from "react";
import { apiCall } from "../../api";

export default class PricingProtected extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      subscribedPlan: props.subscribedPlan || "" // Initialize from props
    };

    this.handleSubscribe = this.handleSubscribe.bind(this);
  }

  componentDidUpdate(prevProps) {
    // Update state if subscribedPlan prop changes
    if (prevProps.subscribedPlan !== this.props.subscribedPlan) {
      this.setState({ subscribedPlan: this.props.subscribedPlan });
    }
  }

  handleSubscribe(planName, priceId) {
    const { email } = this.props;
    const success_url = `${window.location.origin}/profile?session_id={CHECKOUT_SESSION_ID}&payment_id={payment_id}`;
    const cancel_url = `${window.location.origin}/profile?payment_id={payment_id}&cancelled=true`;

    apiCall("/create_checkout_session", {
      data: {
        email: email,
        price_id: priceId,
        success_url: success_url,
        cancel_url: cancel_url
      }
    })
      .then((response) => {
        if (response.data.status === "Success") {
          window.location.href = response.data.session_url;
        } else {
          console.error("Error creating checkout session:", response.data.message);
        }
      })
      .catch((error) => {
        console.error("API call error:", error);
      });
  }

  render() {
    return (
      <React.Fragment>
        <section id="pricing" className={"pricing-section ptb-100 " + (this.props.isWhite && this.props.isWhite === true ? '' : 'gray-light-bg')}>
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-9 col-lg-8">
                <div className="section-heading text-center mb-4">
                  <h2>Packages</h2>
                  <p>
  Tailored AI solutions at a competitive price. Empower your business with intelligent automation and personalized customer interactions through Peachy Assist.
</p>

                </div>
              </div>
            </div>
            <div className="row align-items-center justify-content-md-center justify-content-center">
              <div className="col-12">
                <div className="d-flex justify-content-center text-center">
                  <label className="pricing-switch-wrap">
                    <span className="beforeinput year-switch text-success">
                      Monthly
                    </span>
                    <input type="checkbox" className="d-none" id="js-contcheckbox" />
                    <span className="switch-icon"></span>
                    <span className="afterinput year-switch">
                      Yearly
                    </span>
                  </label>
                </div>
              </div>
              {this.renderPricingCard("Free Plan", "price_1PlL2YLHTBAbRElRfVD42Mcr", "$0", "N/A", "assets/img/priching-img-1.png", ["Single User", "10 messsages / day",  "Email Support"])}
              {this.renderPricingCard("Basic", "price_1PlGiZLHTBAbRElRJXtPax1E", "$39.99", "275.99", "assets/img/priching-img-1.png", ["Up to 3 agents", "100 messages / day",  "Email Support / Limited Phone Support"])}
              {this.renderPricingCard("Platinum Plan", "price_1PlGj0LHTBAbRElRSjKBSQdl",  "$199.99", "$1475.99", "assets/img/priching-img-1.png", ["Up to 50 agents", "1000 messages / day",  "Email Support - Phone Support (EST)"])}
              {this.renderPricingCard("Platinum Plan", "price_1PlGj0LHTBAbRElRSjKBSQdl",  "$999.99", "$1475.99", "assets/img/priching-img-1.png", ["Unlimited agents", "Unlimited messages",  "24/7 Phone Support - Email Support"])}
              <div className="col-12">
                <div className="support-cta text-center mt-5">
                  <h5 className="mb-1"><span className="ti-headphone-alt color-primary mr-3"></span>We're Here to Help You
                  </h5>
                  <p>Have some questions? <a href="/#">Chat with us now</a>, or <a href="/#">send us an email</a> to
                    get in touch.</p>
                </div>
              </div>
              {this.state.subscribedPlan === "" && (
                <div className="col-12">
                  <div className="text-center mt-5">
                    <p className="text-danger">Please subscribe to a plan to take full advantage of Peachy Assist.</p>
                  </div>
                </div>
              )}
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }

  renderPricingCard(planName, priceId, monthlyPrice, yearlyPrice, imgSrc, features) {
    const { subscribedPlan } = this.state;
    const isSubscribed = subscribedPlan === priceId;
    const isUpgrade = this.isUpgrade(priceId);

    return (
      <div className="col-lg-4 col-md-6 col-sm-8">
        <div className={`text-center bg-white single-pricing-pack mt-4 ${isSubscribed ? 'border border-primary' : ''}`}>
          <div className="price-img pt-4">
            <img src={imgSrc} alt="price" width="120" className="img-fluid" />
          </div>
          <div className="py-4 border-0 pricing-header">
            <div className="price text-center mb-0 monthly-price color-secondary" style={{display: "block"}}>{monthlyPrice}<span></span></div>
            <div className="price text-center mb-0 yearly-price color-secondary" style={{display: "none"}}>{yearlyPrice}<span></span></div>
          </div>
          <div className="price-name">
            <h5 className="mb-0">{planName}</h5>
          </div>
          <div className="pricing-content">
            <ul className="list-unstyled mb-4 pricing-feature-list">
              {features.map((feature, index) => (
                <li key={index} className={feature.includes("deem") ? "text-deem" : ""}><span>{feature}</span></li>
              ))}
            </ul>
            {isSubscribed ? (
              <div className="mb-3">
                <span className="text-success">Subscribed</span>
                <br />
                <a href="/#" className="text-danger" style={{ textDecoration: 'underline' }}>Cancel</a>
              </div>
            ) : (
              <button
                onClick={() => this.handleSubscribe(planName, priceId)}
                className={`btn ${isUpgrade ? 'btn-brand-02' : 'btn-outline-brand-02'} btn-rounded mb-3`}
              >
                {isUpgrade ? 'Upgrade' : 'Downgrade'}
              </button>
            )}
          </div>
        </div>
      </div>
    );
  }

  isUpgrade(priceId) {
    const plans = ["price_1PlL2YLHTBAbRElRfVD42Mcr", "price_1PlGiZLHTBAbRElRJXtPax1E", "price_1PlGj0LHTBAbRElRSjKBSQdl"];
    const currentPlanIndex = plans.indexOf(this.state.subscribedPlan);
    const targetPlanIndex = plans.indexOf(priceId);

    return targetPlanIndex > currentPlanIndex;
  }
}
