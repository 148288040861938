import React from "react";
import { connect } from "react-redux";
import { subscribe } from "../../actions/index";

class Footer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        email: "",
    };

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleFormValueChange(inputName, event) {
    let stateValue = {};
    stateValue[inputName] =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    this.setState(stateValue);
  }

  handleSubmit(event) {
    event.preventDefault();
    // get action
    const subscribeAction = subscribe(this.state);

    // Dispatch the contact from data
    this.props.dispatch(subscribeAction);

    // added delay to change button text to previous
    setTimeout(
      function() {
        // get action again to update state
        const subscribeAction = subscribe(this.state);

        // Dispatch the contact from data
        this.props.dispatch(subscribeAction);

        // clear form data
        this.setState({
          email: ""
        });
      }.bind(this),
      3000
    );
  }

  componentDidMount() {
    /**
     * Your ajax will goes here to get data then call setState
     */
  }

  render() {
    return (
      <React.Fragment>
        <footer className={"footer-1 gradient-bg ptb-60 " + (this.props.withoutNewsletter && this.props.withoutNewsletter  === true ? "" : "footer-with-newsletter" )}>
        {!(this.props.withoutNewsletter && this.props.withoutNewsletter  === true ) && (
        <div className="container">
        </div>
        )}

        <div className="container">

        </div>
    </footer>
    <div className="footer-bottom py-3 gray-light-bg">
        <div className="container">
            <div className="row">
                <div className="col-md-6 col-lg-7">
                    <div className="copyright-wrap small-text">
                        <p className="mb-0">&copy; All rights reserved.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
      </React.Fragment>
    );
  }
}

export default connect(state => ({
  state
}))(Footer);
