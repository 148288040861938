import React from "react";
import { connect } from "react-redux";
import { apiCallPublic } from "../../api"; // Import the login function
import { withRouter } from "react-router-dom"; // Import withRouter to enable history

class HeroSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      disableBtn: false,
      btnText: "Sign In",
      errorMessage: "",
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleFormValueChange = this.handleFormValueChange.bind(this);
    this.message = this.message.bind(this);
  }

  changeBtnText = (btnText) => {
    this.setState({ btnText });
  };

  handleFormValueChange(inputName, event) {
    let stateValue = {};
    stateValue[inputName] =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    this.setState(stateValue);
  }

  handleSubmit(event) {
    event.preventDefault();

    const { email, password } = this.state;
    if (!email || !password) {
      this.setState({ errorMessage: "Please fill in all fields." });
      this.message(true);
      return;
    }

    this.setState({ disableBtn: true, btnText: "Signing in..." });

    apiCallPublic("/login", { params: { username: email, password } })
      .then((response) => {
        console.log("Login successful:", response.data);

        const { AuthenticationResult, user_id } = response.data.data;
        const { AccessToken, RefreshToken, IdToken } = AuthenticationResult;

        // Store tokens and user email in local storage
        localStorage.setItem("accessToken", AccessToken);
        localStorage.setItem("refreshToken", RefreshToken);
        localStorage.setItem("userEmail", user_id);
        localStorage.setItem("idToken", IdToken);

        this.setState({ disableBtn: false, btnText: "Sign In", errorMessage: "" });
        this.message(false);

        // Redirect to the main page or dashboard
        this.props.history.push('/'); // Adjust the path as needed
      })
      .catch((error) => {
        console.error("Login error:", error);
        this.setState({
          disableBtn: false,
          btnText: "Sign In",
          errorMessage: "Invalid email or password.",
        });
        this.message(true);
      });
  }

  message(error) {
    const messageBox = document.querySelector('.login-signup-wrap .message-box');
    if (messageBox.classList.contains("d-none")) {
      messageBox.classList.remove("d-none");
    }
    messageBox.classList.add("d-block");
    if (error) {
      if (messageBox.classList.contains("alert-success")) {
        messageBox.classList.remove("alert-success");
      }
      messageBox.classList.add("alert-danger");
      messageBox.innerHTML = 'Found error in the form. Please check again.';
    } else {
      if (messageBox.classList.contains("alert-danger")) {
        messageBox.classList.remove("alert-danger");
      }
      messageBox.classList.add("alert-success");
      messageBox.innerHTML = 'Form submitted successfully';
    }
  }

  render() {
    return (
      <React.Fragment>
        <section className="page-header-section ptb-100 bg-image full-height" image-overlay="8">
          <div className="background-image-wraper" style={{ backgroundImage: "url(assets/img/cta-bg.jpg)", opacity: 1 }}></div>
          <div className="container">
            <div className="row align-items-center justify-content-center">
              <div className="col-12 col-md-8 col-lg-6">
                <div className="login-signup-wrap p-5 gray-light-bg rounded shadow">
                  <div className="login-signup-header text-center">
                    <a href="/"><img src="assets/img/logo-color.png" className="img-fluid mb-3" alt="Logo" /></a>
                    <h4 className="mb-5">Login Your Account</h4>
                  </div>
                  <div className="message-box d-none alert alert-success"></div>
                  <form className="login-signup-form" onSubmit={this.handleSubmit}>
                    <div className="form-group">
                      <label className="pb-1">Email Address</label>
                      <div className="input-group input-group-merge">
                        <div className="input-icon">
                          <span className="ti-email"></span>
                        </div>
                        <input value={this.state.email} onChange={(e) => this.handleFormValueChange("email", e)} type="email" className="form-control" placeholder="name@address.com" />
                      </div>
                    </div>

                    <div className="form-group">
                      <label className="pb-1">Password</label>
                      <div className="input-group input-group-merge">
                        <div className="input-icon">
                          <span className="ti-lock"></span>
                        </div>
                        <input value={this.state.password} onChange={(e) => this.handleFormValueChange("password", e)} type="password" className="form-control" placeholder="Enter your password" />
                      </div>
                    </div>

                    {this.state.errorMessage && (
                      <div className="alert alert-danger" role="alert">
                        {this.state.errorMessage}
                      </div>
                    )}

                    <button className="btn btn-block btn-brand-02 border-radius mt-4 mb-3" disabled={this.state.disableBtn}>
                      {this.state.btnText}
                    </button>
                  </form>
                  <div className="other-login-signup my-3">
                    <div className="or-login-signup text-center">
                      <strong>Or Login With</strong>
                    </div>
                  </div>
                  <ul className="list-inline social-login-signup text-center">
                    <li className="list-inline-item my-1">
                      <a href="/#" className="btn btn-facebook"><i className="fab fa-facebook-f pr-1"></i> Facebook</a>
                    </li>
                    <li className="list-inline-item my-1">
                      <a href="/#" className="btn btn-google"><i className="fab fa-google pr-1"></i> Google</a>
                    </li>
                    <li className="list-inline-item my-1">
                      <a href="/#" className="btn btn-twitter"><i className="fab fa-twitter pr-1"></i> Twitter</a>
                    </li>
                  </ul>
                  <p className="text-center mb-0">Don't have an account? <a href="signup">Register</a></p>
                </div>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-md-8 col-lg-6">
                <div className="copyright-wrap small-text text-center mt-5 text-white">
                  <p className="mb-0">&copy; All rights reserved.</p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default withRouter(connect()(HeroSection));
